import './App.css';
import {EmailManager} from './pages/EmailManager'
import 'bootstrap/dist/css/bootstrap.min.css';
function App() {
  return (
    <div >
     <EmailManager />
    </div>
  );
}

export default App;
